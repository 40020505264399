
.busy-indicator__overlay{
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: .5;
    background: #fff;
}

.busy-indicator__message{
    color: #000;
    font-size: 2em;
    font-weight: bold;
    opacity: 1;
    text-align: center;
}

.busy-indicator__container{
    width: 80px;
    height: 80px;

    position: relative;
    margin: 125px auto;
}

.busy-indicator__double-bounce1, .busy-indicator__double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: black;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.busy-indicator__double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
}