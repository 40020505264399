@import "../../variables";

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

* {
  margin: 0;
}

html {
  height: 100%;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: $blackText;
  height: 100%;
}

button {
  font-family: "Source Sans Pro", sans-serif;
}

.home-page {
  padding: 3em 4.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-page__main {
  width: 100%;
  max-width: 1090px;
  min-width: 1088px;
  margin: 0 auto;
  margin-bottom: 3em;
  flex: 1;
}

.home-page__header {
  width: 100%;
  max-width: 1090px;
  min-width: 1088px;
  margin: 0 auto;
}

a {
  color: $blackText;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.home-page__search-start {
  display: flex;
  position: relative;
}

.francisco-big {
  width: 18.375em;
  height: 21.625em;
  margin-right: 3.813em;
  flex-shrink: 0;
  transition: .5s all ease-out;
}

.couplet {
  font-size: 2.975em;
  font-weight: 700;
  margin-bottom: 5.5rem;
  line-height: 2rem;
}

.couplet__line {
  display: block;
  font-weight: 200;
  margin-bottom: 1.5rem;
  color: $blackText;
}

.couplet__line em {
  color: #41536B;
  font-weight: 900;
  font-style: normal;
}

.search-form {
  display: flex;
  align-items: center;
}

.search-form img {
  fill: red;
}

input {
  padding: .75rem 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #A7A7A7;
  background-color: transparent;
  outline: none;
  font-size: 3em;
  color: $blueText;
}

input::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  /*font-size: 3em;*/
  font-weight: 300;
  font-style: italic;
  color: #CBCBCB;
}

.home-page .search-button {
  border: none;
  background: none;
  padding: 0;
  &:focus{
    outline: none;
  }
  img {
    width: 36px;
    margin-left: -40px;
  }
}

@media screen and (max-width : 1200px) {
  .home-page__main {
    min-width: auto;
  }

  .home-page__header {
    width: 100%;
    min-width: auto;
    margin: 0 auto;
  }

  .couplet,
  input {
    font-size: 2.25em;
  }

  .couplet__line {
    margin-bottom: 1rem;
  }

  .home-page .search-form {
    margin-top: 6.85em;
  }
}

@media screen and (max-width : 1050px) {
  .couplet,
  input {
    font-size: 2em;
  }
  .couplet__line {
    margin-bottom: .75rem;
  }

  .home-page .search-form {
    margin-top: 7.4em;
  }
}

@media screen and (max-width : 1000px) {
  .couplet,
  input{
    font-size: 1.75em;
  }

  .home-page .search-form {
    margin-top: 7.6em;
  }
}

@media screen and (max-width : 930px) {
  .home-page {
    width: 100%;
    padding: 1.5em 1em;
    margin: 0 auto;
  }

  .couplet {
    font-size: 2em;
    /*text-align: center;*/
  }

  input{
    font-size: 2em;
  }

  .home-page .search-form {
    margin-top: 7.6em;
  }

  .home-page__search-start {
    justify-content: center;
  }

  .francisco-big {
    position: fixed;
    /*bottom: 0;*/
    /*right: 0;*/
    left: 0;
    margin: 0 auto;
    transform: translateX(-50%);
    opacity: .3;
  }
}
@media screen and (max-width : 820px) {
  .francisco-big {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: translateY(40%);
    opacity: .3;
  }
}
@media screen and (max-width : $small) {
  .home-page {
    width: 100%;
    padding: 1.5em 1em 0 1em;
    margin: 0 auto;
  }

  .home-page__search-panel {
    width: 100%;
  }
  .home-page__search-start {
    justify-content: unset;
  }

  .home-page .search-form {
    margin-top: 5em;
  }

  .home-page__search-panel .search-button {
    img {
      width: 25px;
      height: 25px;
    }
  }

  .francisco-big {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: translateY(45%);
    opacity: 1;
    padding: 0 1em; // to match the page padding when fixed position
    width: 100%;
  }

  .couplet {
    font-size: 1.125em;
    line-height: 1.5em;
  }

  .couplet__line {
    margin-bottom: 0;
  }

  input {
    font-size: 1.25em;
  }

  .couplet em {
    //display: block;
  }
}