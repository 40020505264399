@import "../../variables.scss";

.search {
  flex: 1 1;
  display: flex;

  /* start: enable momentum-scrolling */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* end: enable momentum scrolling */

  @media (max-width: $small) {
    display: initial;
  }
}
