@import "../../variables.scss";

.expander {
  position: relative;
}

.expander-panel {
  overflow: hidden;
  max-height: 10rem;
  transition: max-height .3s ease-in-out;
}

.expander--expanded .expander-panel {
  max-height: 10000px;
  transition: max-height .6s ease-in-out;
}

/* space for the button */
.expander--overflowing .expander-panel::after {
  content: "";
  display: block;
  height: 2rem;
  margin-bottom: .5rem;
}

.expander__button {
  text-align: start;
  margin: 0;
  position: absolute;
  display: block;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 75%);
  bottom: 0;
  padding: 4rem 0 0 0;
  color: $blueText;
  font-size: .875em;
  user-select: none;
  border: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }
  &:hover{
    text-decoration: underline;
  }
}

.expander--expanded .expander__button {
  background: transparent;
  padding-top: 0;
}