.checkbox {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 122%; /* 100% * 14.09/11.54 */ /* THanks IE! */
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox svg {
  position: absolute;
  display: block;
}

.checkbox__check {
  transition: all .1s ease-in;
}

.checkbox--checked .checkbox__box {
  fill: none;
  stroke: #42536a;
}

.checkbox--checked .checkbox__check {
  fill: #42536a;
  stroke: none;
}

.checkbox--unchecked .checkbox__box {
  stroke: #aaaaaa;
  fill: none;
}

.checkbox--unchecked .checkbox__check {
  fill: #aaaaaa;
  opacity: 0;
  stroke: none;
}

.checkbox--unchecked:hover .checkbox__check,
.checkbox--unchecked.checkbox--hover .checkbox__check {
  opacity: 1;
}
