.tag-filter{
  padding: .5rem;
  transition: color .1s ease-in;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.tag-filter__checkbox {
  width: 0.688rem;
  margin-right: .5rem;
  flex: 0 0 auto;
}

.tag-filter.selected {
    color: #42536a;
  font-weight: bold;
}

.tag-filter:hover {
    /*color: #21ACF6;*/
  cursor: pointer;
}

.tag-filter__name {
    font-size: .75em;
  white-space: normal;
  word-wrap: normal;
}
.tag-filter__count {
    font-size: .75em;
    color: lightgray;
}
