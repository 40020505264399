@import "../../variables";

.inline-highlight {
  display: inline;
}
.search-results {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-top: 13rem;
  width: 58rem;
  z-index: 1;

  @media (max-width: $small) {
    padding: .5em;
    width: 100%;
  }
}

.result {
  max-width: 55rem;
  margin-bottom: 5.625rem;
  position: relative;

  @media (max-width: $small) {
    margin-bottom: 1em;
    border-radius: 0.25em;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    padding: 1em;

    &:hover {
      cursor: pointer;
    }
  }
}

/* would a border separating them help or is it just extra visual noise? */
.result + .result {
  border-top: 1px solid #dddddd;
  padding-top: 1rem;

  @media (max-width: $small) {
    border: none;
  }
}

.result__header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: $small) {
    flex-direction: column-reverse;
    position: absolute;
    padding-left: 4em;
  }
}

.result__ids {
  display: flex;
}

.result__id {
  font-weight: 100;
  color: #aaaaaa;
  margin-right: 0.75em;
}

.result__id + .result__id::before {
  content: "/";
  font-weight: 400;
  color: #aaaaaa;
  font-style: normal;
  margin-right: 0.75em;
}

.result__stig-title {
  max-width: 580px;
}

.result__stig-title a {
  color: $blueText;
  font-weight: 400;
  cursor: pointer;
}

.result__stig-title .highlighter {
  display: inline;
}

.result__stig-title .result__version {
  font-weight: 300;
  margin-left: 0.35em;
}

.result__cat {
  width: 3.961rem;
  height: 4.7rem;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  background: url("../../images/hexagon.svg") no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 1.25rem;
  letter-spacing: 0.05rem;

  @media (max-width: $small) {
    width: 3rem;
    height: 3.726rem;
  }
}

.result__cat--ii {
  background: url("../../images/hexagon-gray.svg") no-repeat;
}

.result__cat--iii {
  color: #999;
  background: url("../../images/hexagon-white.svg") no-repeat;
}

.result__cat-level {
  font-family: "Source Serif Pro", serif;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.125rem;
  padding-left: 0.125rem;
  text-align: center;
}

.result__locator {
  margin-bottom: 1rem;
  display: flex;

  @media (max-width: $small) {
    flex-direction: column;
  }
}

.result__check-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  @media (max-width: $small) {
    font-size: 1.125rem;
    margin-top: 1em;
  }
}

.result__description {
  margin-bottom: 1.688rem;
  line-height: 1.5;

  @media (max-width: $small) {
    margin-bottom: 0;
  }
}

.result__description .expander__button {
  @media (max-width: $small) {
    // hide the 'show more'
    color: transparent;
  }
}

.result__score {
  color: #00aa00;
  opacity: 0.25;
  font-size: smaller;
}

.check-text,
.fix-text {
  display: flex;
  margin-bottom: 1.688rem;
  line-height: 1.5;

  @media (max-width: $small) {
    display: none;
  }
}

.check-text h3,
.fix-text h4 {
  display: block;
  box-sizing: border-box;
  content: "CHECK";
  width: 6rem;
  background-color: #f8f8f8;
  margin-right: 0.625rem;
  font-size: 0.875rem;
  font-weight: 100;
  text-transform: uppercase;
  padding: 0.25rem 0.75rem;
  text-align: center;
  flex-shrink: 0;
}

.check-text p,
.fix-text p {
  flex: 1;
}

.check-text .toggle-container,
.fix-text .toggle-container {
  flex: 1;
}

.highlight {
  background-color: rgba(252, 249, 157, 0.5);
}

.search-results__no-results-found {
  font-size: 1.5rem;
  font-style: italic;
}
.search-results__search-text {
  font-weight: bold;
  font-style: normal;
}

.irrelevant {
  /*opacity: .50;*/
  display: none;
}
