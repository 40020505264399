@import "../../variables";

.about {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  pointer-events: none;
}

.about__content {
  opacity: 0;
  position: absolute;
  left: 380px;
}

.about--visible .about__content {
  opacity: 1;
  transition: opacity 2s;
  transition-delay: .5s;
}

.about__content h1 {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
}

.about__content p {
  font-size: 1.25em;
  margin: 2em 0;
}

.about--visible {
  opacity: 1;
  max-width: 1160px;
}

.about__build{
  font-size: 0.75rem;
  font-weight: 300;
  color: $blackText;
}


@media screen and (max-width : 930px) {
  .about__content {
    opacity: 0;
    position: relative;
    left: auto;
    background: white;
  }

  .about--visible .about__content {
    opacity: 1;
    transition: opacity 1s;
    transition-delay: 0;
  }

}


@media screen and (max-width: $small){
  .about--visible .about__content {
    max-width: 550px;
    font-size: .75em;
  }
}