@import "../../variables.scss";

.stig-results {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding-top: 9rem;
  width: 58rem;
  z-index: 1;

  @media (max-width: $small) {
    padding: 0.5em;
    width: 100%;
  }
}

.stig-results--loading {
  opacity: 0.2;
}
