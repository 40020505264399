@import "../../variables";

.app-footer {
  width: 100%;
  max-width: 1090px;
  min-width: 1088px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 300;
  color: $blackText;
  //overflow: hidden;
  position: relative;
  transition: 1s all ease-in-out;

  @media (max-width: $small) {
    background: #f7f7f7;
    height: 2em;
    width: 100%;
    min-width: auto;
    max-width: initial;
    font-size: 1em;
    z-index: 999;
    // TODO: was hoping to have footer 'raised' but francisco makes this tricky
    //box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

    // TODO: temporary fix to hide footer content
    p,
    nav {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .app-footer {
      min-width: auto;
    }
  }
}

.app-footer .francisco {
  display: none;

  @media (max-width: $small) {
    display: block;
    left: 50%;
    width: 3.25em;
    height: 3em;
    top: -1em;
    //top: calc(100vh - 2.875em);
    //z-index: 999;
    overflow: hidden;
  }
}

.app-footer__menu {
  list-style: none;

  font-weight: 300;
  text-transform: capitalize;
  display: flex;

  width: 10%;
}

.app-footer__menu-item {
  margin-left: 2.25em;
}

@media (max-width: $small) {
  // TODO: doesn't feel right to do this here
  .home-page .app-footer {
    background: transparent;
    height: 17em;
    overflow: hidden;
  }

  // TODO: eww
  .home-page .app-footer .francisco {
    width: 100%;
    overflow: auto;
    height: auto;
    top: 0;
  }
}