@import "../../variables";

.search-result-count {
  font-weight: 300;
  font-size: smaller;
  color: #aaa;
  margin-top: 0.5rem;
  font-style: italic;

  @media (max-width: $small) {
    font-size: 1.125em;
    color: #cbcbcb;
  }
}
