@import "../../variables.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
}

html {
  height: 100%;
  width: 100%;

  // our web site should behave more like an application so the root html element is fixed
  //  this prevents unintended drag to refresh behavior
  position: fixed;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  color: $blackText;
  height: 100%;
  width: 100%;

  // our web site should behave more like an application so the body element hides overflow
  //  and we expect the child elements to manage scrolling content themselves
  overflow: hidden;
}

.app-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: $blackText;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.app-page__main {
  width: 100%;
  /*max-width: 1090px;*/
  min-width: 960px; /* TODO: why did we choose min-width of 1088> */
  /*margin: 0 auto;*/
}

.app-page__main {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: hidden;
  width: 100%;

  @media (max-width: $small) {
    overflow: auto;
    min-width: initial;
  }
}

.app-page__header {
  position: fixed;
  left: 0;
  right: 20px; /* to avoid overlapping standard browser scrollbar width of 17px */
  top: 0;
  z-index: 100;
  pointer-events: none;
}

.app-page__header .app-header__brand a {
  color: white;
  letter-spacing: 0.025em;
  pointer-events: auto;
}

input[type="text"]{
  -webkit-appearance: none;
  border-radius: 0;
}

.link-button {
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: $blueText;
  font-weight: 400;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
  &:focus{
    outline: none
  }
  &:hover{
    text-decoration: underline;
  }
}

/*.container{*/
/*display: grid;*/
/*grid-template-columns:  20% auto;*/
/*grid-gap: .25em;*/
/*}*/
