.francisco-background {
  fill: white;
}

.francisco-face {
  fill: #41536b;
  fill-rule: evenodd;
}


