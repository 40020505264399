@import "../../variables";

.newsletter {
  font-size: 1rem;
  z-index: 9999;
  // TODO: redo the layout so we don't have to do this weirdness
  margin-top: -7em;

  @media screen and (max-width: 930px) {
    position: absolute;
    width: 100%;
  }

  @media screen and (max-width: $small) {
    width: 100%;
  }
}

.newsletter--visible {
  opacity: 1;
  max-width: 1160px;
}

.newsletter__content {
  opacity: 0;
  position: absolute;
  left: 380px;
  padding: 2em;
  // TODO: rework this so we're not hardcoding the width
  max-width: 700px;

  @media screen and (max-width: 930px) {
    opacity: 0;
    position: relative;
    left: auto;
    background: white;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.5);
    max-width: initial;
  }
}

.newsletter__content h2 {
  font-size: 3em;

  @media screen and (max-width: $small) {
    font-size: 1.5em;
  }
}

.newsletter--visible .newsletter__content {
  opacity: 1;
  transition: opacity 2s;
  transition-delay: 0.5s;

  @media screen and (max-width: 930px) {
    opacity: 1;
    transition: opacity 1s;
    transition-delay: 0s;
  }
}

.newsletter__content button {
  border-radius: 4.5px;
  width: 200px;
  height: 65px;
  margin: 0 1em 0 0;
  padding: 0.75em;
  font-size: 1.5em;

  @media screen and (max-width: 930px) {
    width: 200px;
    height: 55px;

    padding: 0.25em;
    font-size: 1.5em;
  }

  @media screen and (max-width: $small) {
    width: 100%;
    height: auto;
    margin: 0 0 1.25em 0;
    padding: 1em;
    font-size: 1.25em;
  }
}

.newsletter__actions {
  margin-top: 3.25em;
}

.newsletter .signup-button {
  border: 1px solid $color-action;
  background: $color-action;
  color: white;
  font-weight: 700;
  -webkit-font-smoothing: antialiased; // light text on darker background can look too bold without this
}

.newsletter--fetching .signup-button {
  border-color: #a1acb3;
  background: #a1acb3;
}

.newsletter--error .signup-button {
  border-color: $color-error;
  background: $color-error;
}

.newsletter .cancel {
  border: 1px solid #949494;
  background: white;
  color: #7a7a7a;
}

.newsletter__fields {
  margin-top: 2em;
}

.newsletter__fields input {
  font-size: 1.875em;
  font-weight: 300;
  color: #333;

  @media screen and (max-width: $small) {
    font-size: 1.25em;
  }
}

.newsletter__fields input + input {
  margin-top: 1em;
}

.newsletter__interface {
  position: relative;
}

.newsletter__confirmation,
.newsletter__error {
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
}

.newsletter__success-message,
.newsletter__error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h3 {
    font-size: 1.5em;
    font-weight: 700;
  }

  p {
    font-size: 1.5em;
  }
}

.newsletter__error-message {
  color: $color-error;
}

.newsletter--signed-up .newsletter__signup-form,
.newsletter--error .newsletter__signup-form {
  opacity: 0;
}