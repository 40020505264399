@import "../../variables.scss";

.stig-details-page {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  /* start: enable momentum-scrolling */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* end: enable momentum scrolling */

  @media (max-width: $small) {
    display: initial;
  }
}

.stig-details-page .francisco {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-30%) translateY(-10%) rotate(20deg);
  width: 19.75em;
  height: 23.125em;
  -webkit-filter: drop-shadow(2px 6px 5px #777);
  z-index: 20;

  @media (max-width: $small) {
    display: none;
  }
}

.stig-header {
  padding: 2em 2em 4em 265px;
  background: #dedede;
  /*width: 100%;*/
  left: 0;
  right: 20px; /* to avoid overlapping standard browser scrollbar width of 17px */
  position: fixed;
  z-index: 10;
  pointer-events: none;

  @media (max-width: $small) {
    background: #f7f7f7;
    padding: 1em;
    position: relative;
  }
}

.stig-header--shrunk {
  padding: 1em 1em 1.5em 265px;
  transition: all 0.2s ease-in-out;
}

.stig-header--shrunk .stig-header__stig-title {
  margin-top: 0.5em;
  font-size: 1em;
}

.stig-header--shrunk .stig-header__stig-version {
  font-size: 1em;
  display: inline-block;
}

.stig-header--shrunk .download-button {
  position: absolute;
  right: 50px;
}

.stig-header__back-button {
  display: block;
  font-size: 0.875em;
  color: #659bc3;
  pointer-events: auto;

  @media (max-width: $small) {
    font-size: unset;
  }
}

.stig-header__stig-title {
  font-size: 2em;
  font-weight: 900;
  margin-top: 1em;
  margin-right: 1em;
  display: inline-block;
  transition: all 0.2s ease-in-out;

  @media (max-width: $small) {
    display: block;
    margin: .25em 0;
    font-size: 1.5em;
  }
}

.stig-header__stig-version {
  font-size: 1em;
  color: #707070;
  margin-top: 0.875em;
  transition: all 0.2s ease-in-out;

  @media (max-width: $small) {
    display: none;
  }
}

.download-button{
  background: url("../../images/download-button.svg") center bottom no-repeat;
  width: 2em;
  height: 2em;
  display: inline-block;
  pointer-events: auto;

  @media (max-width: $small) {
    display: none;
  }
}

.download-button:hover {
  cursor: pointer;
}

.stig-details__results-panel {
  display: flex;
  height: 100%;
  /*overflow: scroll;*/

  @media (max-width: $small) {
    height: auto;
  }
}

.stig-details__results {
  height: 100%;
  flex: 1;
  padding-top: 15rem;
  overflow: auto;

  @media (max-width: $small) {
    padding: 0;
  }
}

.stig-details-page .search-form {
  max-width: 55em;
}

.stig-details-page .search-form__input {
  font-size: 1em;
}

.stig-details-page .cat-filters {
  margin-right: 5.5em;
  /*padding-top: 8em;*/
  padding-top: calc(15rem + 8rem);
  width: 11em;
}

.filter-panel {
  margin-top: 1em;

  @media (max-width: $small) {
    padding: 0.5em;
  }
}

.filter-result-count {
  @media (max-width: $small) {
    font-size: 1.125em;
    color: #cbcbcb;
  }
}

/* TODO: I don't like overriding the styles this way. What would be better
is if the stig-results filled their container and then each page had a specific
sized container that it put the stig-results into
 */
.stig-details-page .stig-results {
  /*max-width: 58rem;*/
  width: auto;
  padding-top: 4em;
  flex: 1;

  @media (max-width: $small) {
    padding-top: 1.5em;
  }
}
