@import "../../variables.scss";

.check-details-page {
  /* start: enable momentum-scrolling */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* end: enable momentum scrolling */
}

.check-header {
  background: #f7f7f7;
  height: 2.25em;
  width: 100%;
  line-height: 2.25em;
  text-align: center;
  position: fixed;
  //display: flex;
  //justify-content: center;
}

.check-header__back {
  position: absolute;
  left: 1em;
  font-weight: 200;
  color: #707070;
}

.check-header__title {
  //display: inline;
  //text-align: center;
  font-weight: 400;
  color: #707070;
}

.check-header__cat {
  display: inline-block;
  width: 0.75em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5em;
}

.check-header__cat--i {
  background: url("../../images/hexagon.svg") no-repeat;
}
.check-header__cat--ii {
  background: url("../../images/hexagon-gray.svg") no-repeat;
}
.check-header__cat--iii {
  background: url("../../images/hexagon-white.svg") no-repeat;
}

.check-details {
  padding: 3.5em 1em 0 1em;
}

.check__stig-title .highlighter {
  display: inline;
}

.check__description {
  margin-bottom: 1.25em;
}

.check__title {
  font-size: 1.625em;
  font-weight: 700;
  margin: 0.625em 0 1em 0;
}

.check__check-text,
.check__fix-text {
  background: #f4f4f4;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 0.5em 0.5em 1.25em 0.5em;
  margin-bottom: 1.5em;
  word-break: break-all;

  h3 {
    font-size: 0.875em;
    text-transform: uppercase;
    margin-bottom: 0.75em;
  }
}

.check__metadata {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;

  dt {
    font-weight: 200;
    color: #999999;
    display:inline-block;
    margin-bottom: .5em;
    width: 5em;             // TODO: hmm
  }
  dd {
    display: inline-block;
    width: calc(100% - 5em); // TODO: hmm
  }
}

.check__cat {
  width: 0.625em;
  height: 0.813em;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  color: white;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-left: .5rem;
  letter-spacing: 0.05rem;
}

.check__cat--i {
  background: url("../../images/hexagon.svg") no-repeat;
}

.check__cat--ii {
  background: url("../../images/hexagon-gray.svg") no-repeat;
}

.check__cat--iii {
  color: #999;
  background: url("../../images/hexagon-white.svg") no-repeat;
}
