@import "../../variables";

.app-header {
  width: 100%;
  max-width: 1200px;
  min-width: 1088px;
  display: flex;
  justify-content: space-between;
  margin-top: 3em;

  @media (max-width: $small) {
    display: none;
  }
}

.app-header__menu {
  list-style: none;
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 10%;
}

.app-header__menu-item {
  margin-left: 2.25em;
  pointer-events: auto;
}

/* This is not right but simplest way for now... */
.home-page .app-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7.438em;
  margin-top: 0;
  max-width: unset;
  min-width: auto;
}

.home-page .app-header h1 {
  font-weight: 900;
  font-size: 1.5em;
  color: inherit;
  position: inherit;
}

/* TODO: let's figure out how to not have to know the container in here*/
.app-page .app-header h1 {
  font-weight: 900;
  font-size: 1rem;
  color: white;
  z-index: 99;
  margin-left: .75rem;
}

@media screen and (max-width : 930px) {
  .home-page .app-header {
    margin-bottom: 3.125em;
  }
}