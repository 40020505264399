// Major Breakpoints
//  here we define an abstract set of device widths that for our specific layout
//  represent the major points where content suddenly looks bad and therefore needs
//  to be moved around
$small: 600px;


// TODO: standardize naming convention
$blueText: #659bc3;
$blackText: #333333;

$color-error: #F3252C;
$color-action: #24AAF2;
