@import "../../variables.scss";

.cat-filters{
    /*background: #00aa00;*/
    width: 265px; /* same as the header left padding */
    /*width: 11rem;*/
    margin-right: 5.250em;
    padding-top: 25em;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    @media (max-width: $small) {
        display: none;
    }
}

.cat-filters__list{
    list-style: none;
    padding: 0 .75rem 1rem .5rem;
}
