.cat-filter{
  padding: .5rem;
  transition: color .1s ease-in;
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.cat-filter__checkbox {
  width: 0.688rem;
  margin-right: .5rem;
  flex: 0 0 auto;
}

.cat-filter.selected {
    color: #42536a;
  font-weight: bold;
}

.cat-filter:hover {
    /*color: #21ACF6;*/
  cursor: pointer;
}

.cat-filter__name {
    font-size: .75em;
  white-space: normal;
  word-wrap: normal;
}

