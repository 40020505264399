
.hex-frame {
  /*opacity: 0;*/
  transform: scale(0) translateY(-430px);
}
.hex-frame {
  position: absolute;
  top: 0;
  left: 110px;
  width: 1260px;
  height: 1144px;
  stroke: #42536A;
  stroke-width: 1px;
  fill: #FFF;
  opacity: 1;
  transition: all .3s ease-in-out;
  transform-origin: 0 250px;
  filter: url(#hex-frame__dropshadow);
}


.about--visible .hex-frame {
  /*opacity: 1;*/
  transform: scale(1) translateY(-430px);
}

.newsletter--visible .hex-frame {
  /*opacity: 1;*/
  transform: scale(1) translateY(-430px);
}

@media screen and (max-width : 930px) {
  .hex-frame {
    display: none;
  }
}
