@import "../../variables.scss";

.tag-filters {
  /*background: #00aa00;*/
  width: 11rem;
  margin-right: 5.25em;
  padding-top: 25em;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media (max-width: $small) {
    display: none;
  }
}

.tag-filters__list {
  list-style: none;
  padding: 0 0.75rem 1rem 0.5rem;
}
