@import '../../_variables.scss';

.matching-stigs{
    background : #eee;
    padding : .55rem 1rem;
    margin-bottom: 1.5rem;
    max-width: 55rem;
}

.matching-stig {
    margin : 1rem 0;
}