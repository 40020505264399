@import '../../_variables.scss';

.selected-filters{
    margin-top: .5rem;
    /*margin-bottom: 4.875rem;*/
}
.selected-filters__list{
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: .875rem;
    font-weight: 100;
}

.selected-filters__list li {
    padding: .25em .75em;
    margin-top: .75em;
}
.selected-filters__list li:first-child {
    font-style: italic;
    color: #aaa;
    padding-left: 0;
}

.selected-filters__item {
    cursor: pointer;
    background: $blueText;
    margin-right: 1em;
    border-radius: 4px;
    color: white;
}

.selected-filters__item:hover,
.selected-filters__clear:hover {
    transition: filter .15s linear;
    filter: brightness(120%);
}

.selected-filters__clear{
    font-size: 0.955rem;
    padding: .25em .75em;
    font-weight: 100;
    color: $blueText;
    cursor: pointer;
    border: 1px solid $blueText;
    border-radius: 4px;
    margin-top: .75em;
}

.selected-filters__item::after {
/*.selected-filters__clear::after {*/

    content: "x";
    font-weight: 500;
    margin-left: 1em;
}