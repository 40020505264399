@import '../../_variables.scss';

.filter-form {
    display: flex;
    align-items: center;
    max-width: 55rem;
}

.filter-form img {
    fill: red;
}

.filter-form__input {
    padding: .75rem 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #A7A7A7;
    background-color: transparent;
    outline: none;
    font-size: 1rem;
    color: $blueText;
}

.filter-form__input::placeholder {
    font-family: "Source Sans Pro", sans-serif;
    /*font-size: 3em;*/
    font-weight: 300;
    font-style: italic;
    color: #CBCBCB;
}

.filter-button {
    border:none;
    background: none;
    margin: 0;
    padding: 0;
    &:focus{
        outline: none;
    }

    img {
        width: 25px;
        height: 25px;
        margin-left: -58px;
    }
}
.filter-result-count {

}

/*!* This is not the right way to do this but... *!*/
/*.home-page .search-panel {*/
/*    margin: 0;*/
/*    width: 100%;*/
/*}*/

/*.home-page .search-button {*/
/*    width: 36px;*/
/*    height: 36px;*/
/*}*/