@import '../../_variables.scss';

.static-content {
  line-height: 2rem;
  margin-top: 1.5em;
  margin-left: 13em;
  width: 57em;
  background: white;
  padding: .5em 1.25em 2em 1.25em;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.static-content a {
  color: $blueText;
}
