@import "../../variables.scss";

.search-start {
  display: flex;
  /*margin-bottom: 4.875rem;*/
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  @media (max-width: $small) {
    position: initial;
  }
}

.francisco {
  position: absolute;
  transform: translateX(-50%);
  width: 19.75em;
  height: 23.125em;
  /*-webkit-filter: drop-shadow( 2px 6px 5px #AAA );*/

  /*TODO: centralize size for breakpoints */
  @media (max-width: $small) {
    display: none;
    //left: 50%;
    //width: 3.25em;
    //height: 3.875em;
    //top: calc(100vh - 2.875em);
    //z-index: 999;
  }
}

.search-start .francisco-background {
  fill: none;
}

.search-panel {
  margin-top: 1.5em;
  margin-left: 13em;
  width: 58em;
  background: white;
  padding: 0.5em 1.25em 2em 1.25em;
  border-radius: 0.25em;
  box-shadow: none;
  //transition: all 1s ease-in;

  @media (max-width: $small) {
    margin: .5em 1em 1em 1em;
    width: 100%;
    box-shadow: none;
    padding: 0;
  }
}

.search-panel--floating {
  box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.3);

  @media (max-width: $small) {
    box-shadow: none;
  }
}

.search-form {
  display: flex;
  align-items: center;
}

.search-form img {
  fill: red;
}

.search-form__input {
  padding: 0.75rem 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #a7a7a7;
  background-color: transparent;
  outline: none;
  font-size: 1.875rem;
  color: $blueText;

  @media (max-width: $small) {
    font-size: 1.125rem;
    padding: 0.5rem 0;
    color: #333;
    font-weight: bold;
  }
}

.search-form__input::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  /*font-size: 3em;*/
  font-weight: 300;
  font-style: italic;
  color: #cbcbcb;
}

.search-button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  &:focus{
    outline: none;
  }
  img {
    width: 25px;
    height: 25px;
    margin-left: -40px;
  }
}

/* This is not the right way to do this but... */
.home-page .search-panel {
  margin: 0;
  width: 100%;
}